import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";

import { axiosPrivate } from "../../api/axios";

const UsersModifyModal = ({
  userId,
  anagraphicUserId,
  open,
  onClose,
  storeName,
  businessName,
  name,
  surname,
  email,
  telephone,
}) => {
  const [newStoreName, setNewStoreName] = useState(null);
  const [newBusinessName, setNewBusinessName] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newSurname, setNewSurname] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [newTelephone, setNewTelephone] = useState(null);

  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleModifies = async () => {
    let modifies = {};

    if (newStoreName !== null && newStoreName !== storeName) {
      modifies.store_name = newStoreName;
    }
    if (newBusinessName !== null && newBusinessName !== businessName) {
      modifies.business_name = newBusinessName;
    }
    if (newName !== null && newName !== name) {
      modifies.name = newName;
    }
    if (newSurname !== null && newSurname !== surname) {
      modifies.surname = newSurname;
    }
    if (newTelephone !== null && newTelephone !== telephone) {
      modifies.telephone = newTelephone;
    }
    try {
      if (Object.keys(modifies).length !== 0) {
        const upload1 = await axiosPrivate.put("/User_Anagraphics/update", {
          id: anagraphicUserId,
          new_values: modifies,
        });
        if (upload1) {
          if (newEmail !== null && newEmail !== email) {
            const upload2 = await axiosPrivate.put("/Users/update", {
              id: userId,
              new_values: { email: newEmail },
            });
            if (!upload2) {
              throw new Error("Cannot update email");
            }
          }
          window.alert("Modifiche effettuate con successo");
          navigate(0);
        }
      } else if (newEmail !== null && newEmail !== email) {
        const upload2 = await axiosPrivate.put("/Users/update", {
          id: userId,
          new_values: { email: newEmail },
        });
        if (upload2) {
          window.alert("Modifiche effettuate con successo");
          navigate(0);
        }
      } else window.alert("Non sono state effettuate modifiche");
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mb: 3 }}
        >
          <Typography variant="h5">
            Modifica il profilo e poi clicca il pulsante per salvare le
            modifiche
          </Typography>
          <Button
            onClick={handleClose}
            sx={{
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "30px",
              minHeight: "30px",
            }}
          >
            <CloseIcon color="error" />
          </Button>
        </Box>
        {/* setStoreName */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Nome farmacia"
            defaultValue={storeName}
            onChange={(e) => setNewStoreName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        {/* setBusinessName */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Ragione sociale"
            defaultValue={businessName}
            onChange={(e) => setNewBusinessName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        {/* setName */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Nome"
            defaultValue={name}
            onChange={(e) => setNewName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        {/* setSurname */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Cognome"
            defaultValue={surname}
            onChange={(e) => setNewSurname(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        {/* setEmail */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="email"
            defaultValue={email}
            onChange={(e) => setNewEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        {/* setTelephone */}
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Telefono"
            defaultValue={telephone}
            onChange={(e) => setNewTelephone(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </Box>
        <Button variant="contained" onClick={handleModifies}>
          Salva modifiche
        </Button>
      </Box>
    </Modal>
  );
};

export default UsersModifyModal;
