import React, { useState } from "react";
import useGetListOf from "../../hooks/useGetListOf";
import useGetUsersForAssignment from "../../hooks/users/useGetUsersForAssignment";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  ImageList,
  ImageListItem,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { axiosPrivate } from "../../api/axios";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PromotionAssignment = ({ idPromotion }) => {
  const navigate = useNavigate(); 
  const clusterTypes = useGetListOf("Cluster_Types", "", "list").listElements;
  const pharmaciesList = useGetUsersForAssignment().listElements;
  const [selectedCluster, setSelectedCluster] = useState();
  const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  const [manualSelectedPharmacies, setManualSelectedPharmacies] = useState([]);
  const [centroids, setCentroids] = useState([]);
  const [centroidsForSelect, setCentroidsForSelect] = useState([]);
  const [selectedCentroids, setSelectedCentroids] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [value, setValue] = useState(0);
  const [searchPharmacy, setSearchPharmacy] = useState("");
  const [nextId, setNextId] = useState(1);
  const [additionalClusters, setAdditionalClusters] = useState([]);

  const handleAnswers = async (event) => {
    // event.preventDefault();

    const choices = selectedPharmacies.map((id) => ({
      user_id: id,
      promotion_id: idPromotion,
      assignment_state_id: 1, // non inviata
      valid: true,
    }));

    try {
      const upload = await axiosPrivate.post(
        "/Promotion_Assignments/create_multiple",
        choices
      );
      if (upload) {
        window.alert("Comunicazione assegnata con successo!");
        navigate(-1);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
    // window.alert(
    //   `verrà mandata la comunicazione agli utenti di id ${selectedPharmacies}`
    // );
  };

  const handleGetClusterElems = async (centroidId) => {
    try {
      const response = await axiosPrivate.get(
        `/Cluster_Elements/list?where_key=cluster_centroid_id&where_value=${centroidId}`
      );
      return response.data.result.list_element.map((elem) => elem.user_id);
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      return [];
    }
  };

  const handleCentroidChange = async (e) => {
    const { value } = e.target;

    if (value.includes("all")) {
      if (allSelected) {
        // Deselect all if "Nessuna" is selected
        setSelectedCentroids([]);
        setSelectedPharmacies(manualSelectedPharmacies);
        setAllSelected(false);
      } else {
        // Select all centroids and users
        const allCentroidIds = centroids.map((centroid) => centroid.id);
        const allUserIds = await Promise.all(
          allCentroidIds.map(handleGetClusterElems)
        );
        const uniqueUserIds = [...new Set(allUserIds.flat())];
        setSelectedCentroids(allCentroidIds);
        setSelectedPharmacies([
          ...new Set([...manualSelectedPharmacies, ...uniqueUserIds]),
        ]);
        setAllSelected(true);
      }
    } else {
      const newSelectedCentroids =
        typeof value === "string" ? value.split(",") : value;
      setSelectedCentroids(newSelectedCentroids);
      setAllSelected(newSelectedCentroids.length === centroids.length);

      const usersToSelect = await Promise.all(
        newSelectedCentroids.map(handleGetClusterElems)
      );
      const uniqueUserIds = [...new Set(usersToSelect.flat())];

      // Mantieni la selezione manuale aggiungendo solo i nuovi utenti selezionati dai centroidi
      setSelectedPharmacies([
        ...new Set([...manualSelectedPharmacies, ...uniqueUserIds]),
      ]);
    }
  };

  const handleCheckboxChange = async (event, pharmacyId) => {
    let updatedSelectedPharmacies;
    if (event.target.checked) {
      updatedSelectedPharmacies = [...selectedPharmacies, pharmacyId];
      setSelectedPharmacies(updatedSelectedPharmacies);
      setManualSelectedPharmacies((prev) => [...prev, pharmacyId]);
    } else {
      updatedSelectedPharmacies = selectedPharmacies.filter(
        (id) => id !== pharmacyId
      );
      setSelectedPharmacies(updatedSelectedPharmacies);
      setManualSelectedPharmacies((prev) =>
        prev.filter((id) => id !== pharmacyId)
      );

      const updatedSelectedCentroids = await Promise.all(
        selectedCentroids.map(async (centroidId) => {
          const centroidPharmacies = await handleGetClusterElems(centroidId);

          const remainingPharmacies = centroidPharmacies.filter((id) =>
            updatedSelectedPharmacies.includes(id)
          );
          return remainingPharmacies.length > 0 ? centroidId : null;
        })
      );

      setSelectedCentroids(updatedSelectedCentroids.filter(Boolean));
    }
  };

  const handleGetCentroids = async (clusterTypeId) => {
    try {
      const response = await axiosPrivate.get(
        `/Cluster_Centroids/list?where_key=cluster_type_id&where_value=${clusterTypeId}`
      );
      if (response) {
        setCentroids((prevCentroids) => [
          ...prevCentroids,
          ...response.data.result.list_element,
        ]);
        setCentroidsForSelect(response.data.result.list_element);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReset = () => {
    setSelectedCentroids([]);
    setSelectedPharmacies([]);
    setSelectedCluster("");
  };

  const renderMoreClusters = () => {
    return <Typography>qui ci sarà una nuova selezione di cluster</Typography>;
  };

  const addClusters = () => {
    setAdditionalClusters([
      ...additionalClusters,
      {
        id: nextId,
      },
    ]);
    setNextId(nextId + 1);
  };

  return (
    <Container component={Paper} sx={{ pt: 1 }}>
      
      {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab
            label={
              <Typography variant="h6" fontSize={15}>
                usando i cluster
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="h6" fontSize={15}>
                scegliendo le singole farmacie
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box> */}

      {/* <CustomTabPanel value={value} index={0}> */}
      <FormControl fullWidth size="small" sx={{ mb: 1, mt: 3 }}>
        <InputLabel id="cluster-select">Seleziona un insieme</InputLabel>
        <Select
          labelId="cluster-select"
          label="Seleziona un insieme"
          onChange={(e) => {
            const selectedClusterId = e.target.value;
            const selectedCluster = clusterTypes.find(
              (cluster) => cluster.id === selectedClusterId
            );
            setSelectedCluster(selectedCluster);
            handleGetCentroids(selectedClusterId);
          }}
          value={selectedCluster?.id || ""}
        >
          {clusterTypes.map((cluster) => (
            <MenuItem key={cluster.id} value={cluster.id}>
              {cluster.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {centroids && centroids.length !== 0 && (
        <>
          <FormControl fullWidth size="small" sx={{ my: 1 }}>
            <InputLabel id="centroid-select">
              Seleziona {selectedCluster?.description || ""}
            </InputLabel>
            <Select
              multiple
              labelId="centroid-select"
              label={`Seleziona ${selectedCluster?.description || ""}`}
              onChange={handleCentroidChange}
              value={selectedCentroids}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const selectedCentroid = centroids.find(
                      (centroid) => centroid.id === value
                    );
                    return (
                      <Chip
                        key={value}
                        label={
                          selectedCentroid ? selectedCentroid.title : value
                        }
                      />
                    );
                  })}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value="all">
                {allSelected ? "-- Nessuna --" : "-- Tutte --"}
              </MenuItem>
              {centroidsForSelect.map((centroid) => (
                <MenuItem key={centroid.id} value={centroid.id}>
                  {centroid.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* {selectedCentroids.length !== 0 && selectedCluster && (
              <Box>
                <Button variant="outlined" onClick={() => addClusters()}>
                  Aggiungi un'altra tipologia di cluster
                </Button>
                <Button
                  sx={{ ml: 3 }}
                  variant="contained"
                  color="error"
                  onClick={handleReset}
                >
                  ELIMINA TUTTI I FILTRI E LE SELEZIONI
                </Button>
              </Box>
            )} */}
        </>
      )}

      {additionalClusters &&
        additionalClusters.length !== 0 &&
        additionalClusters.map(() => {
          return renderMoreClusters();
        })}
      {/* </CustomTabPanel> */}

      <CustomTabPanel value={value} index={1}>
        <TextField
          variant="outlined"
          label="Inserisci il nome di una farmacia"
          sx={{ width: "100%" }}
          onChange={(e) => setSearchPharmacy(e.target.value)}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="start">
                  <Button disabled={searchPharmacy.length === 0 ? true : false}>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            },
          }}
        ></TextField>
      </CustomTabPanel>

      <Box sx={{ height: "auto", pb: 2 }}>
        {pharmaciesList.filter((element) =>
          selectedPharmacies.includes(element["users.id"])
        ).length !== 0 ? (
          <ImageList
            cols={isMobile ? 1 : 3}
            gap={10}
            rowHeight={40}
            style={{
              overflow: "auto",
              height: 300,
              border: "2px solid green",
              borderRadius: 5,
              background: "#ddd",
            }}
          >
            {pharmaciesList
              .filter((element) =>
                selectedPharmacies.includes(element["users.id"])
              )
              .map((element, index) => (
                <ImageListItem key={index} sx={{ m: 1 }}>
                  <FormControlLabel
                    sx={{
                      // width: isMobile ? "45%" : "30%",
                      border: "1px solid #grey",
                      borderRadius: 2,
                      mx: 1,
                      my: 2,
                      p: 0.5,
                      background: "white",
                      "&:hover": { background: "rgba(0, 149, 52, 0.5)" },
                    }}
                    control={
                      <Checkbox
                        checked={selectedPharmacies.includes(
                          element["users.id"]
                        )}
                        onChange={(event) =>
                          handleCheckboxChange(event, element["users.id"])
                        }
                      />
                    }
                    label={
                      <Box>
                        <Typography>
                          <b>
                            [
                            {element["user_anagraphics.customer_internal_code"]}
                            ]
                          </b>{" "}
                          {element["user_anagraphics.store_name"]}
                        </Typography>
                      </Box>
                    }
                  />
                </ImageListItem>
              ))}
          </ImageList>
        ) : (
          <Box
            sx={{
              overflow: "auto",
              height: 300,
              border: "2px solid green",
              borderRadius: 1,
              background: "#ddd",
              alignContent: "center"
            }}
          >
            <Typography fullwidth variant="h6">
              Nessun elemento selezionato
            </Typography>
          </Box>
        )}
      </Box>
      {selectedPharmacies.length !== 0 && (
        <Button sx={{ mb: 5 }} variant="contained" onClick={handleAnswers}>
          Assegna comunicazione
        </Button>
      )}
    </Container>
  );
};

export default PromotionAssignment;
