import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { itIT } from "@mui/x-data-grid/locales";
import { isMobile } from "react-device-detect";
import { axiosPrivate } from "../../api/axios";

const columns = [
  {
    field: "store_name",
    headerName: "Nome farmacia",
    ...(isMobile ? { width: 150 } : { flex: 1 }),
  },
  {
    field: "assignment_state",
    headerName: "Stato comunicazione",
    ...(isMobile ? { width: 150 } : { flex: 1 }),
  },
];

const PromotionAssignmentModal = ({
  open,
  onClose,
  assignment,
  promotionId,
}) => {
  const navigate = useNavigate();

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const handleSelectionChange = (newSelection) => {
    setSelectedUserIds(newSelection);
  };

  const handleMessage = async () => {
    try {
      const upload = await axiosPrivate.post(
        "/Chat_Messages/message_resender_from_promotion",
        selectedUserIds
      );
      if (upload) {
        window.alert("Messaggio inviato con successo!");
        navigate(0)
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };
  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display={"flex"} justifyContent={"Space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Lista farmacie a cui è stata inviata la comunicazione
            </Typography>
            <Button
              color="error"
              onClick={handleClose}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <DataGrid
            rows={assignment}
            columns={columns}
            getRowId={(row) => row.user_id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            onRowSelectionModelChange={(selectedIds) => {
              const selectedRows = assignment.filter((row) =>
                selectedIds.includes(row.user_id)
              );
              handleSelectionChange(selectedRows);
            }}
            checkboxSelection
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />

          <Button
            variant="outlined"
            sx={{ mb: 1, mt: 3 }}
            onClick={handleMessage}
          >
            Reinvia messaggio alle farmacie selezionate
          </Button>
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              navigate(
                `/../../../alphega_centrale/alphega_promotions/${promotionId}/alphega_promotion_assignment/`
              );
            }}
          >
            Assegna comunicazione ad altre farmacie
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PromotionAssignmentModal;
