import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";

const ProfileData = ({ user_type }) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [oldPasswd, setOldPasswd] = useState();
  const [newPasswd, setNewPasswd] = useState();
  const [confirmPasswd, setConfirmPasswd] = useState();
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const userInfo = useGetUserCompleteInfo("");
  const userAnagraphic = userInfo.user_anagraphic;
  const user = userInfo.user;

  const handleOpen = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const validatePassword = (pwd) => {
    const lengthValid = pwd.length >= 8;
    const hasUppercase = /[A-Z]/.test(pwd);
    const hasNumber = /[0-9]/.test(pwd);

    return lengthValid && hasUppercase && hasNumber;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(newPasswd)) {
      setError(
        "La password deve essere lunga almeno 8 caratteri, contenere una maiuscola e un numero."
      );
      return;
    }
    if (newPasswd !== confirmPasswd) {
      setError("Le password non coincidono.");
      return;
    }
    if (newPasswd === oldPasswd) {
      setError("Vecchia e nuova passoword non possono essere uguali.");
      return;
    }

    const passwords = {
      email: "fpiras@alliance-healthcare.it",
      old_password: oldPasswd,
      new_password: newPasswd,
    };

    try {
      const upload = await axiosPrivate.post(
        "/auth/change_password",
        passwords
      );
      if (upload) {
        window.alert("Password impostata con successo!");
        handleClose();
        navigate(0);
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };
  if (user && userAnagraphic) {
    return (
      <>
        <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
          Profilo
          <br />
          <i>{userAnagraphic.store_name}</i>
        </Typography>
        <Container>
          <Box sx={{ px: !isMobile && "10%" }}>
            <TableContainer
              sx={{
                "& .MuiTableCell-root": { fontSize: isMobile ? 14 : 16 },
                boxShadow: 2,
                borderRadius: 2,
                background: "white",
              }}
            >
              <Table
                sx={{ minWidth: isMobile ? "20%" : "40%" }}
                aria-label="simple table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">Nome farmacia</TableCell>
                    <TableCell>{userAnagraphic.store_name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Ragione sociale</TableCell>
                    <TableCell>{userAnagraphic.business_name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Nome</TableCell>
                    <TableCell>{userAnagraphic.name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Cognome</TableCell>
                    <TableCell>{userAnagraphic.surname}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">email</TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Telefono</TableCell>
                    <TableCell>{userAnagraphic.telephone}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {user_type === "Alphega_Centrale" && (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ my: 3 }}
                  onClick={handleOpen}
                >
                  Cambio password
                </Button>
                <Modal open={open} onClose={handleClose}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "50%",
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography variant="h5">Modifica password</Typography>
                      <Button onClick={handleClose}>
                        <CloseIcon sx={{ color: "error.main" }} />
                      </Button>
                    </Box>

                    <Box sx={{ my: 3 }} display={"flex"} alignItems={"center"}>
                      <TextField
                        fullWidth
                        label="Inserisci attuale password"
                        required
                        type="password"
                        onChange={(e) => setOldPasswd(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ my: 3 }} display={"flex"} alignItems={"center"}>
                      <TextField
                        fullWidth
                        label="Inserisci nuova password"
                        required
                        type="password"
                        onChange={(e) => setNewPasswd(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ my: 3 }} display={"flex"} alignItems={"center"}>
                      <TextField
                        fullWidth
                        label="Ripeti nuova password"
                        required
                        type="password"
                        onChange={(e) => setConfirmPasswd(e.target.value)}
                      />
                    </Box>

                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Box display={"flex"} justifyContent={"center"}>
                      <Button variant="contained" onClick={handleSubmit}>
                        Imposta nuova password
                      </Button>
                    </Box>
                  </Box>
                </Modal>
              </>
            )}
          </Box>
        </Container>
      </>
    );
  }
};

export default ProfileData;
