import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import UsersModifyModal from "./../UsersModifyModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import UsersDetailDataNewClusterModal from "./UsersDetailDataNewClusterModal";
import useGetUserCompleteInfo from "../../../hooks/users/useGetUserCompleteInfo";

const UsersDetailData = ({ userId, handleSetBusinessName }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userInfo = useGetUserCompleteInfo(userId);
  const [userAnagraphic, setUserAnagraphic] = useState();
  const [userClusters, setUserClusters] = useState([]);
  const [openModify, setOpenModify] = useState(false);
  const [openAddCluster, setOpenAddCluster] = useState(false);
  const [user, setUser] = useState();
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    if (userInfo) {
      setUser(userInfo.user);
      setUserAnagraphic(userInfo.user_anagraphic);
      setUserClusters(userInfo.user_clusters);

      if (userAnagraphic) handleSetBusinessName(userAnagraphic.store_name);
    }
    if (window.location.href.includes("deactivated")) setIsActive(false);
    else setIsActive(true);
  }, [userInfo, userAnagraphic, userClusters, handleSetBusinessName]);

  const handleDeactivateUser = async () => {
    try {
      const response = await axiosPrivate.post("/Users/suspend_record", {
        id: userId,
      });

      if (response) {
        window.alert("Utenza disabilitata con successo!");
        navigate(`/alphega_centrale/alphega_users/deactivated/${userId}`);
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };
  const handleActivateUser = async () => {
    try {
      const response = await axiosPrivate.post("/Users/reactive_record", {
        id: userId,
      });

      if (response) {
        window.alert("Utenza abilitata con successo!");
        navigate(`/alphega_centrale/alphega_users/${userId}`);
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova.");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };

  const handleDeleteCluster = async (clusterInfo) => {
    try {
      if (
        window.confirm(
          `Sei sicuro di voler escludere la farmacia dall'insieme "${clusterInfo.title}"`
        )
      ) {
        const response = await axiosPrivate.delete(
          `/Cluster_Elements/delete?cluster_centroid_id=${clusterInfo.cluster_centroid_id}&user_id=${userId}`
        );

        if (response) {
          setSearchParams("");
        }
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova.");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };

  const handleSendAccessLinkMessage = async () => {
    try {
      if (
        window.confirm(
          "inviare un nuovo link di accesso alla farmacia su WhatsApp?"
        )
      ) {
        const response = await axiosPrivate.post(
          `/Chat_Messages/access_sender`,
          {
            user_id: userId,
          }
        );

        if (response) {
          window.alert("Link di accesso inviato con successo!");
          setSearchParams("");
        }
      }
    } catch (err) {
      if (!err?.response) {
        window.alert("Errore. Riprova.");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert(`Errore: ${err.response.data.detail}`);
      }
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTableCell-root": { fontSize: isMobile ? 16 : 20 },
          boxShadow: 2,
          borderRadius: 2,
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Codice centralizzato</b>
              </TableCell>
              <TableCell>
                <Typography variant="h6">
                  {userAnagraphic?.customer_internal_code}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Nome farmacia</TableCell>
              <TableCell>
                <b>{userAnagraphic?.store_name}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Ragione sociale</TableCell>
              <TableCell>
                <b>{userAnagraphic?.business_name}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>
                <b>{userAnagraphic?.name}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Cognome</TableCell>
              <TableCell>
                <b>{userAnagraphic?.surname}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <b>{user?.email}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Telefono</TableCell>
              <TableCell>
                <b>{userAnagraphic?.telephone}</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Clusters di appartenenza</TableCell>
              <TableCell>
                <Box>
                  {userClusters?.length !== 0
                    ? userClusters?.map((cluster) => (
                        <Chip
                          color="primary"
                          label={<b>{cluster.title}</b>}
                          onDelete={() => handleDeleteCluster(cluster)}
                          sx={{ p: 3, m: 0.5 }}
                        />
                      ))
                    : "La farmacia non appartiene a nessun cluster"}
                  <Tooltip
                    name="Aggiungi ad un nuovo gruppo"
                    followCursor
                    arrow
                  >
                    <IconButton
                      color="primary"
                      size="large"
                      onClick={() => setOpenAddCluster(true)}
                    >
                      <AddIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                  </Tooltip>
                  {openAddCluster && (
                    <UsersDetailDataNewClusterModal
                      open={openAddCluster}
                      onClose={() => setOpenAddCluster(false)}
                      actualClusters={userClusters}
                      userId={userId}
                    />
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        onClick={() => setOpenModify(true)}
        endIcon={<EditIcon sx={{ fontSize: 20 }} />}
        sx={{ m: 3 }}
      >
        Modifica profilo farmacia
      </Button>
      {isActive ? (
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (
              window.confirm(
                "Sei sicuro di volere disabilitare questa farmacia? Non potrà più accedere al portale fino a un'eventuale riattivazione."
              )
            )
              handleDeactivateUser();
          }}
          endIcon={<CloseIcon sx={{ fontSize: 20 }} />}
          sx={{ m: 3 }}
        >
          Disabilita farmacia
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (
              window.confirm(
                "Sei sicuro di volere abilitare questa farmacia? Potrà nuovamente accedere al portale."
              )
            )
              handleActivateUser();
          }}
          endIcon={<CheckIcon sx={{ fontSize: 20 }} />}
          sx={{ m: 3 }}
        >
          Abilita farmacia
        </Button>
      )}
      <Button
        variant="contained"
        onClick={handleSendAccessLinkMessage}
        endIcon={<WhatsAppIcon sx={{ fontSize: 20 }} />}
        sx={{ m: 3 }}
      >
        Invia link di accesso al portale
      </Button>
      {openModify && (
        <UsersModifyModal
          userId={userId}
          anagraphicUserId={userAnagraphic?.id}
          open={openModify}
          onClose={() => setOpenModify(false)}
          storeName={userAnagraphic?.store_name}
          businessName={userAnagraphic?.business_name}
          name={userAnagraphic?.name}
          surname={userAnagraphic?.surname}
          email={user?.email}
          telephone={userAnagraphic?.telephone}
        />
      )}
    </>
  );
};

export default UsersDetailData;
