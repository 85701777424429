import React, { useState } from "react";

import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import Textinput from "./edit_input/Textinput";
import MapSelect from "./edit_input/MapSelect";
import MultiCheckbox from "./edit_input/MultiCheckbox";
import LinkComponent from "./edit_input/LinkComponent";
import SingleCheckbox from "./edit_input/SingleCheckbox";
import QuantitySelect from "./edit_input/QuantitySelect";
import PricingTable from "./edit_input/PricingTable";
import DateTimeSelect from "./edit_input/DateTimeSelect";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useGetPromotionTypes from "../../hooks/promotions/useGetPromotionTypes";
import ImageDisplay from "./edit_input/ImageDisplay";
import TextDispaly from "./edit_input/TextDispaly";
import ImageCarouselDisplay from "./edit_input/ImageCarouselDisplay";

const PromotionEditor = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [components, setComponents] = useState([]);
  const [nextId, setNextId] = useState(0);
  const [cover, setCover] = useState(null);
  const [promotionTitle, setPromotionTitle] = useState("");
  const [promotionDescription, setPromotionDescription] = useState("");
  const [type, setType] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [disableDate, setDisableDate] = useState(true);
  const [files, setFiles] = useState([]);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageComponentExists, setImageComponentExists] = useState(false);
  const [imageCarouselComponentExists, setImageCarouselComponentExists] = useState(false);

  const promotionTypes = useGetPromotionTypes();

  //funzione per caricare i dati della creazione del form sul database
  const submitComponents = async (buttonType) => {
    let formattedStartDate = null;
    let formattedEndDate = null;

    if (startDate !== null) {
      formattedStartDate = dayjs(startDate).format("YYYY-MM-DD HH:mm:ss.SSS");
    } // NON CAMBIARE MAI

    if (endDate !== null) {
      formattedEndDate = dayjs(endDate).format("YYYY-MM-DD HH:mm:ss.SSS");
    } // NON CAMBIARE MAI

    const promotionData = {
      typology_id: type,
      title: promotionTitle,
      description: promotionDescription,
      start: formattedStartDate,
      end: formattedEndDate,
    };

    const formData = new FormData();

    formData.append("promotion", JSON.stringify(promotionData));

    if (components && components.length > 0) {
      formData.append(
        "list_promotion_component",
        components.map((c) => JSON.stringify(c)).join(",")
      );
    }

    if (cover) {
      formData.append("promotion_cover", cover);
    }

    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("list_promotion_attachment", files[i]);
      }
    }

    if (imagesToDisplay.length !== 0) {
      for (let i = 0; i < imagesToDisplay.length; i++) {
        formData.append("list_images_to_display", imagesToDisplay[i]);
      }
    }

    try {
      const upload = await axiosPrivate.post(
        "/Promotions/create_complete",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (upload) {
        window.alert("Comunicazione creata con successo!");
        navigate(
          `/alphega_centrale/alphega_promotions/${upload.data.result.res_id}/`
        );
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  //gestisco cover (singolo)
  const handleSingleFileChange = (event) => {
    const file = event.target.files[0];
    setCover(file);
  };

  //gestisco attachment multipli (limite a 3 file caricati e 50MB di peso massimo)
  const handleMultipleFilesChange = (event) => {
    const selectedFiles = Array.from(event);
    const maxFiles = 3;
    const maxSizeMB = 50;
    const maxSizeBytes = maxSizeMB * 1024 * 1024;

    if (selectedFiles.length + files.length > maxFiles) {
      alert(`Puoi caricare al massimo ${maxFiles} file.`);
      return;
    }

    const totalSize = selectedFiles.reduce(
      (total, file) => total + file.size,
      files.reduce((total, file) => total + file.size, 0)
    );

    if (totalSize > maxSizeBytes) {
      alert(`Il peso massimo totale dei file è ${maxSizeMB} MB.`);
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  //rimuovo file (singoli e multipli)
  const handleRemoveFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  //aggiungo componente della comunicazione
  const addComponent = (input_type_id) => {
    const previousHeight = document.body.scrollHeight;
    setComponents([
      ...components,
      {
        input_type_id,
        order: nextId,
        title: "",
        options: {
          description: "",
          choices: [],
        },
      },
    ]);
    setNextId(nextId + 1);
    setTimeout(() => {
      const newHeight = document.body.scrollHeight;

      window.scrollBy({
        top: newHeight - previousHeight,
        behavior: "smooth",
      });
    }, 100);
  };

  //rimuovo componente della comunicazione
  const removeComponent = (id) => {
    setComponents(components.filter((component) => component.order !== id));
  };

  //salvo i dettagli del componente singolo
  const saveComponent = (id, title, description, choices, obligatory) => {
    let options = {
      description: description,
      choices: [],
    };

    if (choices.length !== 0)
      choices.map((option, index) => {
        options.choices.push({
          id: index,
          label: option,
        });
      });

    options = JSON.stringify(options)

    setComponents((prevComponents) =>
      prevComponents.map((component) =>
        component.order === id
          ? { ...component, title, options, obligatory }
          : component
      )
    );
  };

  ///visualizzo i vari componenti creati
  const renderComponent = (component) => {
    switch (component.input_type_id) {
      case 1:
        return (
          <Textinput
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 2:
        return (
          <SingleCheckbox
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 3:
        return (
          <MultiCheckbox
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 4:
        return (
          <LinkComponent
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 5:
        return (
          <QuantitySelect
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 6:
        return (
          <MapSelect
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 7:
        return (
          <PricingTable
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 8:
        return (
          <DateTimeSelect
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 9:
        return (
          <ImageDisplay
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 10:
        return (
          <TextDispaly
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      case 11:
        return (
          <ImageCarouselDisplay
            key={component.order}
            componentId={component.order}
            saveComponent={saveComponent}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container component={Paper} sx={{ pt: 1, px: isMobile && 0 }}>
      <Box mx={4}>
        {/* TITOLO */}
        <TextField
          fullWidth
          label="Titolo della comunicazione*"
          onChange={(e) => setPromotionTitle(e.target.value)}
          style={{ marginTop: 8 }}
        />

        {/* DESCRIZIONE */}
        <TextField
          fullWidth
          label="Descrizione generale della comunicazione"
          multiline
          rows={2}
          onChange={(e) => setPromotionDescription(e.target.value)}
          style={{ marginTop: 16 }}
        />

        {/* TIPOLOGIA */}

        <FormControl fullWidth sx={{ my: 2 }}>
          <InputLabel>Seleziona la tipologia di comunicazione*</InputLabel>
          <Select
            size="small"
            label="Seleziona la tipologia di comunicazione*"
            id="simple-select"
            onChange={(e) => setType(e.target.value)}
            defaultValue={1}
          >
            {promotionTypes &&
              promotionTypes.map((type, index) => (
                <MenuItem key={index} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {/* DATA INIZIO */}
        <Box
          sx={{
            mx: "auto",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon /> Data inizio comunicazione
                </Box>
              }
              minDateTime={dayjs()}
              maxDateTime={endDate}
              ampm={false}
              value={startDate}
              format="DD/MM/YYYY HH:mm"
              onChange={(date) => {
                setStartDate(date);
                setDisableDate(false);
              }}
              onError={(value) => {
                if (value !== null) setDisableDate(true);
              }}
              sx={{ width: "45%" }}
            />
          </LocalizationProvider>

          {/* DATA FINE */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon /> Data fine comunicazione
                </Box>
              }
              minDateTime={startDate}
              ampm={false}
              disabled={disableDate}
              value={endDate}
              format="DD/MM/YYYY HH:mm"
              onChange={(date) => setEndDate(date)}
              sx={{ width: "45%" }}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ p: 1, mt: 3, border: "1px solid grey", borderRadius: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            {/* UPLOAD FILE SINGOLO */}
            <Box sx={{ width: "50%", borderRight: "1px solid grey", px: 0.5 }}>
              Carica copertina
              <br />
              <Input
                id="file-input"
                type="file"
                onChange={(e) => handleSingleFileChange(e)}
                style={{ display: "none" }}
                inputProps={{
                  accept: "image/png, image/jpeg",
                }}
              />
              <label htmlFor="file-input">
                <Button
                  size="small"
                  variant="contained"
                  component="span"
                  sx={{ display: cover && "none", mt: 1 }}
                >
                  <AddIcon sx={{ fontSize: 40 }} />
                </Button>
              </label>
              {/* area file selezionati (visualizzazione file singolo per mobile) */}
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cover && (
                  <Button
                    variant="contained"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      whiteSpace: "normal",
                      wordBreak: "break-all",
                      textAlign: "center",
                      fontSize: 10,
                      mt: 1,
                      mx: "auto",
                    }}
                  >
                    {cover.name}
                    <IconButton onClick={() => setCover(null)} sx={{ my: -1 }}>
                      <CloseIcon />
                    </IconButton>
                  </Button>
                )}
              </Box>
            </Box>

            {/* UPLOAD FILE MULTIPLI */}
            <Box sx={{ width: "50%", borderLeft: "1px solid grey", px: 0.5 }}>
              Carica allegati
              <br />
              <Input
                id="multiple-files-input"
                type="file"
                onChange={(e) => handleMultipleFilesChange(e.target.files)}
                style={{ display: "none" }}
                inputProps={{
                  accept:
                    "image/png, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  multiple: true,
                }}
              />
              <label htmlFor="multiple-files-input">
                <Button
                  size="small"
                  variant="contained"
                  component="span"
                  sx={{ display: files.length === 3 && "none", mt: 1 }}
                >
                  <AddIcon sx={{ fontSize: 40 }} />
                </Button>
              </label>
              {/* Area file selezionati (visualizzazione file multipli per mobile e tutto per pc*/}
              <Box
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {files.length > 0 &&
                  files.map((file, index) => (
                    <Button
                      variant="contained"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        whiteSpace: "normal",
                        wordBreak: "break-all",
                        textAlign: "center",
                        fontSize: 10,
                        mt: 1,
                        mx: "auto",
                      }}
                    >
                      {file.name}
                      <IconButton
                        onClick={() => handleRemoveFile(file)}
                        sx={{ my: -1 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Button>
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider
          variant="middle"
          flexItem
          sx={{ bgcolor: "#009534", p: 0.1, m: 3 }}
        />
      </Box>
      <Box
        position="sticky"
        top={isMobile ? 0 : 118}
        sx={{
          background: "rgba(244,246,248,0.75)",
          zIndex: 100,
          p: 1,
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          mx: -3,
        }}
      >
        <Box sx={{ width: "100%" }}>
          Seleziona un elemento per comporre la comunicazione
        </Box>

        {/* INPUT TESTO */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(1)}
          startIcon={<AddIcon />}
        >
          Risposta libera
        </Button>

        {/* SCELTA SINGOLA */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(2)}
          startIcon={<AddIcon />}
        >
          Selezione singola
        </Button>

        {/* SCELTA MULTIPLA */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(3)}
          startIcon={<AddIcon />}
        >
          Selezione multipla
        </Button>

        {/* LINK */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(4)}
          startIcon={<AddIcon />}
        >
          Link
        </Button>

        {/* QUANTITA' */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(5)}
          startIcon={<AddIcon />}
        >
          Quantità
        </Button>

        {/* MAPPA LUOGO */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(6)}
          startIcon={<AddIcon />}
        >
          Luogo
        </Button>

        {/* TABELLA */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(7)}
          startIcon={<AddIcon />}
        >
          Tabella
        </Button>

        {/* DATA E ORA */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(8)}
          startIcon={<AddIcon />}
        >
          Data e ora
        </Button>

        {/* IMMAGINI */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => {
            setImageComponentExists(true);
            addComponent(9);
          }}
          startIcon={<AddIcon />}
          disabled={imageComponentExists === true ? true : false}
        >
          Immagini
        </Button>

        {/* CAROSELLO IMMAGINI */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => {
            setImageCarouselComponentExists(true);
            addComponent(11);
          }}
          startIcon={<AddIcon />}
          disabled={imageCarouselComponentExists === true ? true : false}
        >
          Carosello immagini
        </Button>

        {/* TESTO */}
        <Button
          sx={{ m: 0.5, width: "170px" }}
          variant="contained"
          onClick={() => addComponent(11)}
          startIcon={<AddIcon />}
        >
          Testo
        </Button>
      </Box>
      {components.map((component) => (
        <Box key={component.order} sx={{ py: 1 }}>
          {renderComponent(component)}
          <Button
            sx={{ m: 3 }}
            variant="outlined"
            color="error"
            onClick={() => {
              if (component.input_type_id === 9) setImageComponentExists(false);
              removeComponent(component.order);
            }}
          >
            Elimina componente
          </Button>
          <Divider
            variant="middle"
            flexItem
            sx={{ bgcolor: "#009534", p: 0.1 }}
          />
        </Box>
      ))}
      {components.length !== 0 &&
        promotionTitle &&
        type && (
          <Box sx={{ position: "sticky", bottom: 0, zIndex: 200 }}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              onClick={submitComponents}
              sx={{ mb: 1, boxShadow: 10 }}
            >
              Salva
            </Button>
          </Box>
        )}
    </Container>
  );
};

export default PromotionEditor;
