import { Box, Button, Typography } from "@mui/material";
import React from "react";
import LaunchIcon from '@mui/icons-material/Launch';
import { isMobile } from "react-device-detect";

const Link = ({ description }) => {
  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <Typography sx={{ mb: 3, whiteSpace: "pre-line" }} variant="body1">
        {description}
      </Typography> */}
      <Button size="large" variant="contained" onClick={() => window.open(description)} sx={{ width: isMobile ? "75%" : "50%"  }} endIcon={<LaunchIcon />}>
        APRI LINK
      </Button>
    </Box>
  );
};

export default Link;
