import React, { useState, useRef, useEffect } from "react";
import { Box, MobileStepper, Button, useTheme, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { isMobile } from "react-device-detect";

const ImagesCarousel = ({ options }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = options.length;
  const [expanded, setExpanded] = useState(false);
  const imageContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleScroll = () => {
    if (imageContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = imageContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const scroll = (direction) => {
    if (imageContainerRef.current) {
      const scrollAmount = 250; // Valore di scroll per ogni click
      imageContainerRef.current.scrollBy({ left: direction * scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleScroll(); // Controlla lo stato iniziale dello scroll
  }, [expanded]);

  return (
    <Box sx={{ width: "100%", flexGrow: 1, textAlign: "center", background: "darkgrey", zIndex: expanded && 10000, position: expanded && "relative" }}>
      {/* Pulsante ESPANDI/RIDUCI */}
      <Box sx={{ position: expanded && "sticky", top: 0, background: "#f4f6f8", alignItems: "center", zIndex: 100 }}>
        <Button
          fullWidth
          size="large"
          variant="contained"
          endIcon={expanded ? <ZoomOutIcon sx={{ fontSize: "25pt" }} /> : <ZoomInIcon sx={{ fontSize: "25pt" }} />}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "RIDUCI" : "ESPANDI"}
        </Button>
      </Box>

      {/* Contenitore immagini con overflow */}
      <Box sx={{ display: "flex" }}>
        {/* Frecce di scorrimento quando expanded è attivo */}
        {!isMobile && expanded && canScrollLeft && (
          <Box
            component={Button}
            onClick={() => scroll(-1)}
            sx={{
              mr: -8,
              zIndex: 2,
              background: "rgba(0,0,0,0.3)",
              color: "white",
              "&:hover": { background: "rgba(0,0,0,0.8)" },
            }}
          >
            <KeyboardArrowLeft sx={{ fontSize: "40pt", height: "100vh" }} />
          </Box>
        )}

        <Box
          ref={imageContainerRef}
          onScroll={handleScroll}
          sx={{
            display: "flex",
            justifyContent: !expanded ? "center" : "flex-start",
            alignItems: "center",
            position: "relative",
            height: expanded ? (isMobile ? "150vh" : "200vh") : 300,
            width: "100%",
            overflowX: expanded ? "auto" : "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            scrollBehavior: "smooth",
          }}
        >

          {/* Immagine attuale */}
          <img
            src={`data:image/png;base64,${options[activeStep].label}`}
            alt={`Image ${activeStep + 1}`}
            style={{
              objectFit: "contain",
              width: !expanded ? "100%" : "auto",
              height: expanded ? "75%" : "100%",
            }}
          />

        </Box>
        {/* Freccia a destra */}
        {!isMobile && expanded && canScrollRight && (
          <Box
            component={Button}
            onClick={() => scroll(1)}
            sx={{
              ml: -8,
              zIndex: 2,
              background: "rgba(0,0,0,0.3)",
              color: "white",
              "&:hover": { background: "rgba(0,0,0,0.8)" },
            }}
          >
            <KeyboardArrowRight sx={{ fontSize: "40pt" }} />
          </Box>
        )}
      </Box>

      {/* Stepper */}
      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="large" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            SUCC
            <KeyboardArrowRight sx={{ fontSize: "25pt" }} />
          </Button>
        }
        backButton={
          <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft sx={{ fontSize: "25pt" }} />
            PREC
          </Button>
        }
      />
    </Box>
  );
};

export default ImagesCarousel;
