import React from "react";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import { Box, Divider, Paper, TextField } from "@mui/material";

import NavBar from "../navbar/NavBar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";

const ProfileDataEdit = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const userData = useGetUserCompleteInfo("");

  const [profile, setProfile] = useState({
    name: null,
    surname: null,
    username: null,
    birth: null,
    telephone: null,
    email: null,
  });

  useEffect(() => {
    if (userData.length !== 0 && userData !== null) {
      setProfile({
        ...profile,
        name:  userData.user_anagraphic ? userData.user_anagraphic["name"] || null : null,
        surname: userData.user_anagraphic ? userData.user_anagraphic["surname"] || null : null,
        birth: userData.user_anagraphic ? userData.user_anagraphic["birth"] || null : null,
        store_name: userData.user_anagraphic ? userData.user_anagraphic["store_name"] || null : null,
        business_name: userData.user_anagraphic ? userData.user_anagraphic["business_name"] || null : null,
        address: userData.user_address ? userData.user_address["address"] || null : null,
        city: userData.user_address ? userData.user_address["city"] || null : null,
        cap: userData.user_address ? userData.user_address["cap"] || null : null,
        province: userData.user_address ? userData.user_address["province"] || null : null,
        nation: userData.user_address ? userData.user_address["nation"] || null : null,
        telephone: userData.user_anagraphic ? userData.user_anagraphic["telephone"] || null : null,
        email: userData.user ? userData.user["email"] || null : null,
      });
    }
  }, [userData, profile]);


  if (userData.length !== 0 && userData !== null) {
    var userType = "";

    switch (userData.user["role"]) {
      case "Admin":
        userType = "6957";
        break;

    //   case "Broker":
    //     userType = "7304";
    //     break;

    //   case "Client":
    //     userType = "8297";
    //     break;

    //   case "Controller":
    //     userType = "2508";
    //     break;

      default:
        break;
    }

    const handleInputChange = (e) => {
      let { name, value } = e.target;

      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: value,
      }));
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      let payload = {
        id: userData.user["id"],
        anagraphics: {
          name: profile.name,
          surname: profile.surname,
          store_name: profile.store_name,
          business_name: profile.business_name,
          birth: profile.birth,
          telephone: profile.telephone,
        },
        address: {
          address: profile.address,
          city: profile.city,
          cap: profile.cap,
          province: profile.province,
          nation: profile.nation,
        }
      }

      if(userData.user_anagraphic)
        payload.anagraphics.id = userData.user_anagraphic["id"]

      if(userData.user_address)
        payload.address.id = userData.user_address["id"]

      try {
        await axiosPrivate.put("/users/update_information", payload);

        window.alert("Modifica avvenuta con successo!");
        window.location.href = "/profilo";
      } catch (err) {
        if (!err?.response) {
          window.alert("Errore. Riprova");
        } else if (err.response?.status === 400) {
          window.alert("Username o password mancante!");
        } else if (err.response?.status === 401) {
          window.alert("Non sei autorizzato");
        } else {
          window.alert("Errore. Riprova");
        }
      }
    };

    return (
      <React.Fragment>
        <NavBar value={userType} />
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          pt={"75px"}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container component={Paper} maxWidth={"sm"} sx={{ pt: 5 }}>
            <h2>Modifica profilo</h2>
            <br />
            <br />
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    key="Nome"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Nome
                    </TableCell>
                    <TextField
                      name="name"
                      type="text"
                      align="right"
                      value={profile.name}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Cognome"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Cognome
                    </TableCell>
                    <TextField
                      name="surname"
                      type="text"
                      align="right"
                      value={profile.surname}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Data di nascita"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Data di nascita
                    </TableCell>
                    <TextField
                      name="birth"
                      type="date"
                      align="right"
                      value={profile.birth}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Nome farmacia"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Nome farmacia
                    </TableCell>
                    <TextField
                      name="store_name"
                      type="text"
                      align="right"
                      value={profile.store_name}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Ragione sociale"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Ragione sociale
                    </TableCell>
                    <TextField
                      name="business_name"
                      type="text"
                      align="right"
                      value={profile.business_name}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Indirizzo"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Indirizzo
                    </TableCell>
                    <TextField
                      name="address"
                      type="text"
                      align="right"
                      value={profile.address}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Città"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Città
                    </TableCell>
                    <TextField
                      name="city"
                      type="text"
                      align="right"
                      value={profile.city}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Cap"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Cap
                    </TableCell>
                    <TextField
                      name="cap"
                      type="text"
                      align="right"
                      value={profile.cap}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Provincia"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Provincia
                    </TableCell>
                    <TextField
                      name="province"
                      type="text"
                      align="right"
                      value={profile.province}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Paese"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Paese
                    </TableCell>
                    <TextField
                      name="nation"
                      type="text"
                      align="right"
                      value={profile.nation}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  <TableRow
                    key="Telefono"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Telefono
                    </TableCell>
                    <TextField
                      name="telephone"
                      type="text"
                      align="right"
                      value={profile.telephone}
                      onChange={handleInputChange}
                      sx={{ fontSize: 20, my: 1, width: "100%" }}
                    />
                  </TableRow>
                  {/* <TableRow
                  key="Email"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    Email
                  </TableCell>
                  <TextField
                    type="email"
                    align="right"
                    value={profile.email}
                    onChange={(e) => {
                      setProfile({ ...profile, email: e.target.value });
                    }}
                    sx={{ fontSize: 20, my: 1, width: "100%" }}
                  />
                </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider sx={{ my: 2 }} />
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ mx: 2, width: "40%" }}
            >
              Salva
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ mx: 2, width: "40%" }}
              onClick={() => navigate(-1)}
            >
              Annulla
            </Button>
            <br />
            <br />
          </Container>
        </Box>
      </React.Fragment>
    );
  }
};

export default ProfileDataEdit;
