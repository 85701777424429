import { Box, Typography } from "@mui/material";
import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { isMobile } from "react-device-detect";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const Map = ({ description, options }) => {
  const lat = parseFloat(options[0].label);
  const lng = parseFloat(options[1].label);

  return (
    <Box
      sx={{
        display: isMobile ? "block" : "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        mb: 5,
        py: 2,
        borderRadius: 5,
        boxShadow: 2
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "justify" }}>
        {description}
      </Typography>
      <Box
        sx={{ position: "relative", height: "300px", width: isMobile ? "100%" : "70%", zIndex: 1 }}
      >
        <MapContainer
          center={[lat, lng]}
          zoom={13}
          style={{ height: "100%", width: "100%" }}
          dragging={true}
          touchZoom={true}
          scrollWheelZoom={true}
          doubleClickZoom={true}
          boxZoom={true}
          keyboard={true}
          zoomControl={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[lat, lng]} />
        </MapContainer>
      </Box>
    </Box>
  );
};

export default Map;
