import {
  Box,
  Container,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import UsersDetailData from "./users_detail_elems/UsersDetailData";
import PropTypes from "prop-types";
import UsersDetailPromotions from "./users_detail_elems/UsersDetailPromotions";
import UsersDetailFiles from "./users_detail_elems/UsersDetailFiles";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsersDetail = ({ userId }) => {
  const [businessName, setBusinessName] = useState();
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSetBusinessName = (store_name) => {
    setBusinessName(store_name);
  };

  return (
    <>
      <Typography component="h1" variant="h5" sx={{ my: 3, mb: 1 }}>
        Dettaglio farmacia "{businessName}"
      </Typography>
      <Container component={Paper} sx={{ pt: 1, px: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <Tab
              label={
                <Typography variant="h6" fontSize={15}>
                  DATI
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography variant="h6" fontSize={15}>
                  COMUNICAZIONI
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography variant="h6" fontSize={15}>
                  FILES
                </Typography>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box sx={{ mt: -2 }}>
          <CustomTabPanel value={value} index={0}>
            <UsersDetailData
              userId={userId}
              handleSetBusinessName={handleSetBusinessName}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <UsersDetailPromotions userId={userId} />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <UsersDetailFiles userId={userId} />
          </CustomTabPanel>
        </Box>
      </Container>
    </>
  );
};

export default UsersDetail;
