import {
  Box,
  Button,
  Grid2,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const ImageCarouselDisplay = ({
  componentId,
  saveComponent,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [previews, setPreviews] = useState([]);
  const [images, setImages] = useState([]);

  const handleSave = () => {
    console.log(images); // Qui vedrai le immagini come stringhe base64
    saveComponent(componentId, title, description, images, false);
  };

  const handleSetPreviews = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + previews.length > 6) {
      alert("Puoi selezionare un massimo di 6 file tra il componente immagini e il componente carosello immagini");
      return;
    }

    // Creazione delle anteprime
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);

    // Conversione dei file in stringhe base64
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Ottieni la parte base64
        setImages((prevImages) => [...prevImages, base64String]);
      };
      reader.readAsDataURL(file); // Legge il file e lo converte in base64
    });
  };

  const handleRemovePreview = (indexToRemove) => {
    setPreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== indexToRemove)
    );
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  useEffect(() => {
    handleSave();
  }, [title, description, images]);

  return (
    <>
      <Typography variant="h5">Immagini</Typography>
      Crea un elemento carosello che contiene immagini massimo 3 immagini. Le immagini si potranno scorrere ed ingrandire.
      <Box sx={{ padding: 2 }}>
        <Input
          id="imageCarouselToDisplay"
          type="file"
          onChange={handleSetPreviews}
          style={{ display: "none" }}
          inputProps={{
            accept: "image/png, image/jpeg",
            multiple: true,
          }}
        />
        <label htmlFor="imageCarouselToDisplay">
          <Button
            variant="contained"
            component="span"
            disabled={previews.length >= 3 ? true : false}
          >
            Seleziona File
          </Button>
        </label>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {previews.map((preview, index) => (
            <Grid2 item key={index} xs={4}>
              <Box>
                <Box
                  component="img"
                  src={preview}
                  alt={`Preview ${index + 1}`}
                  sx={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: 1,
                    m: 1,
                  }}
                />
                <br />
                <IconButton
                  onClick={() => handleRemovePreview(index)}
                  color="error"
                  sx={{
                    top: 8,
                    right: 8,
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Grid2>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ImageCarouselDisplay;
