import { Box, TextField, Typography } from "@mui/material";

const TextInput = ({
  description,
  componentId,
  handleAnswers,
  answer,
  disabled,
  obligatory,
}) => {
  const handleChoice = (choice) => {
    const answerData = {
      componentId: componentId,
      answer: choice,
      obligatory: obligatory, 
    };
    handleAnswers(answerData);
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      
      <Typography variant="body1" sx={{ textAlign: "justify" }}>{description}</Typography>
      <TextField
        id="outlined-multiline-flexible"
        disabled={disabled}
        defaultValue={answer}
        multiline
        rows={4}
        style={{ width: "90%" }}
        sx={{ mt: 2 }}
        onChange={(e) => handleChoice(e.target.value)}
      />
    </Box>
  );
};

export default TextInput;
