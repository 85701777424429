import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box, IconButton, Typography } from "@mui/material";

const InfoBar = ({ userData }) => {
  return (
    <Box
      sx={{
        background: "#009534",
        display: "flex",
        p: 1.5,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ ml: "6.5%", display: "flex" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.242"
          height="21.446"
          viewBox="0 0 22.242 21.446"
        >
          <path
            d="M16.621,9.921H14.893V11.7H13.352V9.921H11.625V8.332h1.727V6.553h1.541V8.332h1.727Zm4.317-3.034a3.92,3.92,0,0,0-2.11-2.621l-.064-.027a4.477,4.477,0,0,0-4.642.306,4.486,4.486,0,0,0-4.646-.306l-.06.027a3.925,3.925,0,0,0-2.11,2.621,4.121,4.121,0,0,0,.678,3.467,7.009,7.009,0,0,0,1.93,1.779,14.263,14.263,0,0,1,4.04,3.392.2.2,0,0,0,.167.091.2.2,0,0,0,.167-.091,14.263,14.263,0,0,1,4.04-3.392,7.009,7.009,0,0,0,1.93-1.779A4.122,4.122,0,0,0,20.938,6.886Zm4.293,4.226-.969,6.709a2.938,2.938,0,0,1-1.216,1.994L19.6,22.225a1.651,1.651,0,0,0-.66.977,1.5,1.5,0,0,0,.064.842c.039.127.155.381.246.579.051.116.1.223.13.3a.193.193,0,0,1-.017.186.2.2,0,0,1-.172.091H15.948a.2.2,0,0,1-.194-.147l-.341-1.171-.207-1.024a6.954,6.954,0,0,1,.271-3.4,3.149,3.149,0,0,1,1.218-1.592l1.628-1.072a2.9,2.9,0,0,0,.72-.683L20.52,14.1a1.071,1.071,0,0,1,1.365-.312l0,.06a.713.713,0,0,1-.012.126,2.164,2.164,0,0,1-.259.735A10.772,10.772,0,0,1,19.8,17.169l-.306.31a.3.3,0,0,0,0,.422.311.311,0,0,0,.211.087.294.294,0,0,0,.211-.091l.306-.31a11.462,11.462,0,0,0,1.926-2.625,2.656,2.656,0,0,0,.315-.91,2.073,2.073,0,0,0,.016-.215.149.149,0,0,0,0-.043l-.073-.6.778-2.563a1.055,1.055,0,0,1,1.2-.73A1.068,1.068,0,0,1,25.231,11.112ZM13.042,22.848l-.2,1.017-.35,1.188a.2.2,0,0,1-.194.147H9.045a.205.205,0,0,1-.172-.091.193.193,0,0,1-.016-.186c.026-.075.078-.182.13-.3.091-.2.207-.453.246-.579A1.529,1.529,0,0,0,9.3,23.2a1.656,1.656,0,0,0-.66-.976l-3.444-2.41a2.937,2.937,0,0,1-1.216-1.994l-.964-6.712A1.07,1.07,0,0,1,3.847,9.9a1.058,1.058,0,0,1,1.2.73l.726,2.4-.021.811a2.118,2.118,0,0,0,.016.215,2.735,2.735,0,0,0,.325.933,11.526,11.526,0,0,0,1.913,2.6l.306.31a.291.291,0,0,0,.211.091.315.315,0,0,0,.211-.087.3.3,0,0,0,0-.422l-.306-.31A10.8,10.8,0,0,1,6.61,14.677a2.084,2.084,0,0,1-.246-.716.823.823,0,0,1-.013-.126v-.052a1.067,1.067,0,0,1,1.369.308L9.2,16.1a2.815,2.815,0,0,0,.72.683l1.628,1.072a3.141,3.141,0,0,1,1.219,1.593,6.965,6.965,0,0,1,.273,3.4Z"
            transform="translate(-3.001 -3.754)"
            fill="#fff"
          ></path>
        </svg>
        <Typography
          component="h1"
          variant="h6"
          color="white"
          fontSize={15}
          fontWeight={600}
          sx={{ mx: 0.5 }}
        >
          Benvenuto in Alphega, {userData.store_name}
        </Typography>
      </Box>
      <Box sx={{ my: -1, mr: "8%" }}>
        <IconButton
          sx={{ color: "white" }}
          onClick={() =>
            window.open("https://www.facebook.com/alphegafarmacia")
          }
        >
          <FacebookIcon sx={{ scale: "140%" }} />
        </IconButton>

        <IconButton
          sx={{ color: "white" }}
          onClick={() =>
            window.open("https://it.linkedin.com/alphega-farmacia-italia")
          }
        >
          <LinkedInIcon sx={{ scale: "140%" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default InfoBar;
