import { Box, Checkbox, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const CheckboxMultipleInput = ({
  description,
  componentId,
  options,
  handleAnswers,
  answer,
  disabled,
  obligatory,
}) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(answer)) {
      setValue(answer); //metto la risposta se esiste
    } else {
      setValue([]); //metto array vuoto
    }
  }, [answer]);

  const handleChoice = (newValue) => {
    const answerData = {
      componentId: componentId,
      answer: newValue,
      obligatory: obligatory,
    };
    handleAnswers(answerData);
  };

  const handleCheckboxChange = (label, checked) => {
    let newValue;
    if (checked) {
      newValue = [...value, label]; //aggiungo elemento
    } else {
      newValue = value.filter((item) => item !== label); //tolgo elemento
    }
    setValue(newValue);
    handleChoice(newValue);
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 6,
        mx: "5%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="body1" sx={{ mb: 1, textAlign: "justify" }}>
        {description}
      </Typography>
      <List>
        {options.map((option, index) => (
          <ListItem key={index}>
            <Checkbox
              value={option.label}
              disabled={disabled}
              checked={value.includes(option.label)}
              onChange={(e) =>
                handleCheckboxChange(option.label, e.target.checked)
              }
            />
            <Box sx={{ color: disabled && "#9E9E9E" }}>{option.label}</Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CheckboxMultipleInput;
