import { Box, Button, Typography } from "@mui/material";
import React from "react";

const DateTime = ({ description, options }) => {
  const dateString = options[0].label;
  const date = new Date(dateString);

  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  const formattedTime = `${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  return (
    <Box>
      <Typography sx={{ textAlign: "justify" }}>{description}</Typography>
      <Box
        size="large"
        variant="contained"
        sx={{ border: "1px solid #ddd", borderRadius: 3, m: 2, color: "white", fontWeight: 700, background: "#009534", mx: "25%", py: 2 }}
      >
        Data: {formattedDate}
        <br />
        Ore: {formattedTime}
      </Box>
    </Box>
  );
};

export default DateTime;
