import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import PromotionsTypeListFilter from "../promotions/PromotionsTypeListFilter";

const FilesDataDisplay = ({ photoData, promotionsData, pharmaciesData }) => {
  const promotionTypes = useGetListOf(
    "Promotion_Typologies",
    "limit=1000",
    "list"
  ).listElements;
  const axiosPrivate = useAxiosPrivate();

  const [expandImage, setExpandImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageData, setSelectedImageData] = useState(null);

  const [promotions, setPromotions] = useState([]);

  const [pharmacies, setPharmacies] = useState([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState("");

  const [photos, setPhotos] = useState();
  const [loadedPhotos, setLoadedPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPromotion, setSelectedPromotion] = useState("");
  const [filteredPromotions, setFilteredPromotions] = useState([]);

  const [isFirstInitialLoad, setIsFirstInitialLoad] = useState(true); //per foto
  const [isSecondInitialLoad, setIsSecondInitialLoad] = useState(true); //per promozioni

  const [hover, setHover] = useState(false);

  const handleDownload = async (file) => {
    try {
      const response = await axiosPrivate.get(
        `/Files/get_file_by_id?id=${file.id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      const fileName =
        selectedImageData.title +
        "-" +
        selectedImageData.customer_internal_code +
        "-" +
        file.path.replace(/^.*[\\/]/, "");
      link.download = fileName.replace(/ /g, "_");
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Errore durante il download:", err);
      window.alert("Errore durante il download. Riprova.");
    }
  };

  const obtainPhoto = async (idFile) => {
    try {
      const response = await axiosPrivate.get(
        `/Files/get_file_by_id?id=${idFile}`,
        {
          responseType: "arraybuffer",
        }
      );
      const isImageRequest =
        response.headers["content-type"].startsWith("image/");

      if (isImageRequest) {
        const base64Image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        return `data:${response.headers["content-type"]};base64,${base64Image}`;
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 400) {
        window.alert("Username o password mancante!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
      return null;
    }
  };

  useEffect(() => {
    if (pharmaciesData) {
      setPharmacies(pharmaciesData.listElements);
    }

    if (promotionsData) {
      setPromotions(promotionsData.listElements);
      if (
        promotionsData.listElements.length !== 0 &&
        filteredPromotions.length === 0 &&
        isSecondInitialLoad
      ) {
        setFilteredPromotions(promotionsData.listElements);
        setIsSecondInitialLoad(false);
      }
    }

    if (photoData) {
      setPhotos(photoData.listElements);
      if (
        photoData.listElements.length !== 0 &&
        filteredPhotos.length === 0 &&
        isFirstInitialLoad
      ) {
        setFilteredPhotos(photoData.listElements);
        setIsFirstInitialLoad(false);
      }
    }
  }, [
    photoData,
    filteredPhotos.length,
    filteredPromotions.length,
    isFirstInitialLoad,
    isSecondInitialLoad,
    promotionsData,
  ]);

  useEffect(() => {
    if (photos && photos.length > 0 && loadedPhotos.length === 0) {
      const loadPhotos = async () => {
        const photoPromises = photos.map((photo) => obtainPhoto(photo.id));
        const photosData = await Promise.all(photoPromises);
        setLoadedPhotos(photosData.filter((photo) => photo !== null));
      };
      loadPhotos();
    }
  }, [photos, loadedPhotos.length]);

  const handleOpen = (photo) => {
    setSelectedImage(photo);
    setExpandImage(true);
  };

  const handleClose = () => {
    setExpandImage(false);
    setSelectedImage(null);
  };

  const handleFilter = async () => {
    let filtered = photos;

    if (description !== "") {
      filtered = filtered.filter((photo) => photo.title === description);
    }

    if (searchTerm !== "") {
      filtered = filtered.filter(
        (photo) =>
          photo.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          photo.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedPromotion !== "") {
      filtered = filtered.filter((photo) =>
        photo.description
          .toLowerCase()
          .includes(selectedPromotion.toLowerCase())
      );
    }

    if (selectedPharmacy["users.id"]) {
      filtered = filtered.filter((photo) => {
        return photo.user_id === selectedPharmacy["users.id"];
      });
    }

    const photoPromises = filtered.map((photo) => obtainPhoto(photo.id));
    const photosData = await Promise.all(photoPromises);

    setLoadedPhotos(photosData.filter((photo) => photo !== null));
    setFilteredPhotos(filtered);
  };

  const clearFilter = async () => {
    setDescription("");
    setSearchTerm("");
    setSelectedPromotion("");
    setSelectedPharmacy("");
    const photoPromises = photos.map((photo) => obtainPhoto(photo.id));
    const photosData = await Promise.all(photoPromises);

    setLoadedPhotos(photosData.filter((photo) => photo !== null));
    setFilteredPhotos(photos);
  };

  const filterPromotions = (id) => {
    let filtered = promotions;
    filtered = filtered.filter((prom) => prom.typology_id === id);
    setFilteredPromotions(filtered);
  };

  return (
    <Container component={Paper} sx={{ pt: 1, px: 1, borderRadius: 3 }}>
      <Typography sx={{ my: 1 }}>
        Cerca tra le immagini caricate dalle farmacie impostando i filtri e
        cliccando su "CERCA"
      </Typography>
      <Accordion
        sx={{
          background: "#009534",
          border: "5px solid #009534",
          borderBottomRightRadius: 15,
          borderBottomLeftRadius: 15,
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ color: "white", fontSize: "30pt" }} />
          }
          aria-controls="panel1-content"
          id="panel1-header"
          color="primary"
        >
          <Typography variant="h6" fontSize={16} color="white">
            Mostra filtri
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "white", borderRadius: 3 }}>
          <Box>
            {/* <FormControl size="small" sx={{ width: "100%", mb: 1 }}>
              <InputLabel sx={{ my: "auto" }}>
                Cerca per categoria comunicazione
              </InputLabel>
              <Select
                value={description}
                label="Cerca per categoria comunicazione"
                onChange={(e) => {
                  {
                    const selectedType = promotionTypes.find(
                      (type) => type.description === e.target.value
                    );
                    if (selectedType) {
                      setDescription(selectedType.description);
                      filterPromotions(selectedType.id);
                    } else setDescription("");
                  }
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                <MenuItem value="">-</MenuItem>
                {promotionTypes.map((type) => (
                  <MenuItem key={type.id} value={type.description}>
                    {type.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Box sx={{ mb: 1 }}>
              <PromotionsTypeListFilter
                onTypeSet={(id, type, description) => {
                  {
                    const selectedType = promotionTypes.find(
                      (type) => type.description === description
                    );
                    if (selectedType) {
                      setDescription(selectedType.description);
                      filterPromotions(selectedType.id);
                    } else setDescription("");
                  }
                }}
                searchParams={""}
                variant="photo-archive"
              />
            </Box>
            <FormControl size="small" sx={{ width: "100%", mb: 1 }}>
              <InputLabel>Cerca per comunicazione</InputLabel>
              <Select
                value={selectedPromotion}
                label="Cerca per comunicazione"
                onChange={(e) => {
                  setSelectedPromotion(e.target.value);
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                <MenuItem value="">-</MenuItem>
                {filteredPromotions.map((prom) => (
                  <MenuItem value={prom.title}>{prom.title}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {pharmacies && pharmacies.length !== 0 && (
              <Autocomplete
                value={selectedPharmacy}
                onChange={(event, newValue) => {
                  //   filtro le comunicazioni di quella farmacia
                  if (newValue) setSelectedPharmacy(newValue);
                  else setSelectedPharmacy("");
                }}
                size="small"
                disablePortal
                options={pharmacies}
                getOptionLabel={(option) =>
                  option
                    ? `[${option["user_anagraphics.customer_internal_code"]}] ${option["user_anagraphics.store_name"]}`
                    : ""
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cerca per farmacia ( [codice centralizzato] - nome farmacia )"
                  />
                )}
                sx={{ mb: 1 }}
                ListboxProps={{
                  style: {
                    maxHeight: 300,
                  },
                }}
              />
            )}

            <TextField
              label="Cerca parola chiave"
              id="searchbar"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleFilter();
                }
              }}
              sx={{ mb: 1, width: "100%" }}
            />

            <Box sx={{ display: "flex", mb: -1 }}>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  handleFilter();
                }}
                sx={{ width: "85%" }}
              >
                CERCA
              </Button>
              <Button
                size="large"
                color="error"
                variant="contained"
                sx={{ width: "12%", ml: "3%" }}
                disabled={
                  description === "" &&
                  searchTerm === "" &&
                  selectedPromotion === "" &&
                  selectedPharmacy === ""
                }
                onClick={clearFilter}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {photoData.totalElements > 0 ? (
        <>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              // width: "95%",
              // boxShadow: 12,
              margin: "auto",
            }}
          >
            {filteredPhotos.length !== 0 ? (
              <ImageList
                variant="woven"
                cols={isMobile ? 2 : 5}
                gap={16}
                sx={{ background: "transparent", height: 500 }}
              >
                {filteredPhotos.map((photo, index) => (
                  <Box
                    key={index}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <ImageListItem
                      sx={{
                        height: 300,
                        border: "2px solid #ddd",
                        boxShadow: 5,
                        "&:hover": {
                          border: "5px solid rgba(0, 149, 52, 0.5)",
                          borderRadius: 2,
                        },
                      }}
                    >
                      <img
                        src={loadedPhotos[index]}
                        alt={`${index}`}
                        onClick={() => {
                          setSelectedImageData(photo);
                          handleOpen(loadedPhotos[index]);
                        }}
                        style={{ cursor: "pointer", borderRadius: 5 }}
                      />
                      <ImageListItemBar
                        sx={{
                          background: "rgba(0, 0, 0, 0.8)",
                        }}
                        title={photo.title}
                        subtitle={
                          <span>
                            {photo.description}
                            <br />[{photo.customer_internal_code}] -{" "}
                            {photo.store_name}
                          </span>
                        }
                        actionIcon={
                          hover && (
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <IconButton
                                onClick={() => handleDownload(photo)}
                                sx={{ color: "white" }}
                              >
                                <FileDownloadIcon />
                              </IconButton>
                            </Box>
                          )
                        }
                      />
                    </ImageListItem>
                  </Box>
                ))}
              </ImageList>
            ) : (
              <Typography
                component="h1"
                variant="h6"
                color="secondary"
                sx={{ m: 3 }}
              >
                Nessun risultato.
              </Typography>
            )}
          </Box>

          <Modal open={expandImage} onClose={handleClose} closeAfterTransition>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleClose}
            >
              <Box
                onClick={(e) => e.stopPropagation()} //evita che si chiuda quando clicco immagine
                display="flex"
                flexDirection="column"
                // justifyContent="center"
                alignItems="center"
                sx={{
                  maxWidth: "80vw",
                  maxHeight: "80vh",
                }}
              >
                {selectedImage && selectedImageData && (
                  <>
                    <img
                      src={selectedImage}
                      alt="Expanded"
                      style={{
                        maxHeight: "80vh",
                        maxWidth: "80vw",
                        marginTop: 30,
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        display: "flex",
                        justifyContent: "space-between",
                        background: "white",
                        width: isMobile ? "95%" : "50%",
                        borderRadius: 2,
                      }}
                    >
                      <Box sx={{ m: 1, width: "50%" }}>
                        <Box sx={{ borderBottom: "1px solid #ddd" }}>
                          Categoria:{isMobile && <br />}{" "}
                          {selectedImageData.title}
                        </Box>
                        <Box sx={{ borderBottom: "1px solid #ddd" }}>
                          Comunicazione:{isMobile && <br />}{" "}
                          {selectedImageData.description}
                        </Box>
                        <Box>
                          Farmacia:{isMobile && <br />}{" "}
                          <b>
                            [{selectedImageData.customer_internal_code}]{" "}
                            {isMobile ? <br /> : "-"}{" "}
                            {selectedImageData.store_name}
                          </b>
                        </Box>
                      </Box>
                      <Box sx={{ m: 1 }}>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownload(selectedImageData)}
                          sx={{ mb: 0.5 }}
                        >
                          <FileDownloadIcon />
                          Scarica
                        </Button>
                        <Button
                          fullWidth
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          <CloseIcon />
                          Chiudi
                        </Button>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </div>
          </Modal>
        </>
      ) : (
        <Typography variant="h6" sx={{ color: "black", mb: 3 }}>
          Nessuna immagine disponibile
        </Typography>
      )}
    </Container>
  );
};

export default FilesDataDisplay;
